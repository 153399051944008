rm-step-progress,
rm-step-contact-details,
rm-step-paper-selector,
rm-step-map-builder {
  display: block;
}
rm-step-progress.inactive,
rm-step-contact-details.inactive,
rm-step-paper-selector.inactive,
rm-step-map-builder.inactive {
  display: none;
}

rm-step-contact-details,
rm-step-paper-selector {
  margin-bottom: 96px;
}

rm-step-progress {
  margin-right: 16px;
}
rm-step-progress ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
rm-step-progress ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
rm-step-progress ul li.connector {
  height: 1px;
  background-color: white;
  min-width: var(--rm-step-progress-connector--width);
  margin: var(--rm-step-progress-connector--margin);
}
rm-step-progress ul li.selected {
  color: var(--peppermint);
  border-radius: 50%;
  animation: pulse 2s infinite;
}

@media screen and (max-width: 480px), screen and (max-height: 780px) {
  rm-step-contact-details {
    padding-bottom: 75px;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeIn {
  animation: fadeIn 0.25s forwards;
}

.fadeOut {
  animation: fadeOut 0.25s forwards;
}
/*# sourceMappingURL=steps.d45fa30d.css.map */
