rm-step-progress,
rm-step-contact-details,
rm-step-paper-selector,
rm-step-map-builder {
    display: block;

    &.inactive {
        display: none;
    }
}

rm-step-contact-details,
rm-step-paper-selector {
    margin-bottom: 96px;
}

rm-step-progress {
    margin-right: 16px;
    
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 2rem;
            height: 2rem;
            cursor: pointer;

            &.connector {
                height: 1px;
                background-color: white;
                min-width: var(--rm-step-progress-connector--width);
                margin: var(--rm-step-progress-connector--margin);
            }

            &.selected {
                color: var(--peppermint);
                border-radius: 50%;
                animation: pulse 2s infinite;
            }
        }
    }
}
@media screen and (max-width: 480px), screen and (max-height: 780px) {
    rm-step-contact-details {
        padding-bottom: 75px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fadeIn {
    animation: fadeIn 0.25s forwards;
}
.fadeOut {
    animation: fadeOut 0.25s forwards;
}